<template>
  <div class="bigDiv">
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/08/172042326257040958.png" alt="" class="Bodyimg">
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/08/172042328829666220.gif" alt="" class="btoImg" @click="throttleSubmit">
    <div class="btoText" v-if="tg_id==3">南京崇如翼科技有限公司</div>
    <div class="btoText" v-else>苏州九和智慧科技有限公司</div>
    <div v-if="tg_id==3">
      <div class="btoText">南京市麒麟科技创新园天骄路100号江苏南京侨梦苑A栋5楼201室
      </div>
      <div class="btoText bto">13400055698</div>
    </div>
    <div v-else>
      <div class="btoText">苏州工业园区唯和路139号1号厂房4121室
      </div>
      <div class="btoText bto">15051446181</div>
    </div>
  </div>
</template>

<script>
import { getWxCsad } from "../../api/360AD.js";
export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {

      tg_id: '',
      ruleForm: {

        land_link: "", //落地页链接
      },
    }
  },
  created () {
    this.ruleForm.land_link = window.location.href
    this.tg_id = this.$route.query.tg_id
  },
  methods: {
    throttleSubmit () {
      const currentTime = Date.now();
      if (currentTime - this.lastClickTime < 2500) {
        return; // 在一秒内多次点击，直接返回
      }
      this.lastClickTime = currentTime; // 更新上次点击时间

      this.wxClick()
    },
    async wxClick () {
      const { data } = await getWxCsad(this.ruleForm);
      window.location.href = data.data;
    },
  },
  mounted () {

  },
  watch: {

  },
  computed: {

  },
  filters: {

  }
}
</script>

<style scoped lang='less'>
.bigDiv {
  width: 100%;
  overflow: hidden;
  .Bodyimg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .btoImg {
    width: 375px;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .btoText {
    width: 100%;
    text-align: center;
    margin-bottom: 100px;
    font-size: 20px;
    color: #ccc;
    margin: 10px auto;
  }
  .bto {
    margin-bottom: 100px;
  }
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 750px;
    margin: 0 auto;
    overflow: hidden;
    .Bodyimg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .btoImg {
      width: 750px;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
    }
    .btoText {
      width: 100%;
      text-align: center;
      font-size: 20px;
      color: #ccc;
      margin: 10px auto;
    }
    .bto {
      margin-bottom: 150px;
    }
  }
}
</style>